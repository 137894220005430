import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Icon1 from "../components/landingPage/assets/Icon1.webp";
import Icon2 from "../../src/assets/Review.webp";
import secondBanner from "../components/landingPage/assets/Rectangle-1.webp";
import Phone from "../components/landingPage/assets/phone.webp";
import { BiCheckCircle } from "react-icons/bi";
import Phones from "../components/landingPage/assets/Phones.webp";
import Laptops from "../components/landingPage/assets/laptops.webp";
import ps5 from "../components/landingPage/assets/ps5.webp";
import tablets from "../components/landingPage/assets/tablets.webp";
import TestimonialsRepair from "../components/repairGadgets/TestimonialsRepair";
import Apps from "../components/landingPage/assets/Apps.webp";
import { Select } from "antd";
import { Helmet } from "react-helmet";

const LandingPage = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();

  const onChange = (value) => {
    setSelectedOption(value);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleGetStarted = () => {
    if (selectedOption === "care") {
      navigate("/care");
    } else if (selectedOption === "repair") {
      navigate("/repair");
    } else if (selectedOption === "certified_devices") {
      window.location.href = "https://www.gadgetsreborn.store/";
    }
  };

  const onSearch = (val) => {
    console.log("search:", val);
  };

  return (
    <div className="flex flex-col sm:mx-[50px]">
      <Helmet>
        <meta
          name="title"
          content="UAE’s #1 Marketplace for Mobile & Laptop Repair & Care | Gadgets Reborn"
        />
        <meta
          name="description"
          content="Gadgets Reborn – UAE’s top destination for reliable mobile and laptop repair. From iPhones to Samsung and beyond, we provide expert, trusted care for all your gadget needs. Quality repairs, seamless service, and peace of mind in one place."
        />
        <link rel="canonical" href="https://www.gadgetsreborn.com/" />
        <meta
          name="keywords"
          content="apple iPhone,iphone 14,iphone 15,iphone 14 pro max,iphone 15 pro max,iphone 15 pro max price,iphone 15 for sale,best iphone 15 deals,iphone 14 repair and care,iphone 15 repair and care,iphone 14 repair and service,iphone 15 repair and service,samsung S23 Ultra repair service,iphone screen repair,Repair Iphone,Dubai Warranty extension,Refurbished iphone,refurbished mobiles uae "
        />
        <meta
          property="og:title"
          content=" UAE’s #1 Marketplace for Mobile & Laptop Repair & Care | Gadgets Reborn "
        />
        <meta property="og:site_name" content="Gadgets Reborn" />
        <meta property="og:url" content="https://www.gadgetsreborn.com/" />
        <meta
          property="og:description"
          content="Gadgets Reborn – UAE’s top destination for reliable mobile and laptop repair. From iPhones to Samsung and beyond, we provide expert, trusted care for all your gadget needs. Quality repairs, seamless service, and peace of mind in one place."
        />
        <meta property="og:type" content="Website" />
        <meta
          name="twitter:title"
          content=" UAE’s #1 Marketplace for Mobile & Laptop Repair & Care | Gadgets Reborn "
        />
        <meta
          name="twitter:description"
          content=" Gadgets Reborn – UAE’s top destination for reliable mobile and laptop repair. From iPhones to Samsung and beyond, we provide expert, trusted care for all your gadget needs. Quality repairs, seamless service, and peace of mind in one place."
        />
        <meta
          itemprop="title"
          content=" UAE’s #1 Marketplace for Mobile & Laptop Repair & Care | Gadgets Reborn "
        />
        <meta
          itemprop="description"
          content=" Gadgets Reborn – UAE’s top destination for reliable mobile and laptop repair. From iPhones to Samsung and beyond, we provide expert, trusted care for all your gadget needs. Quality repairs, seamless service, and peace of mind in one place."
        />

        <meta
          name="headline"
          content="Gadgets Reborn: Your online repair platform for Mobile & Laptop Repair and Care"
        />
        <meta
          name="description"
          content="Trusted mobile and laptop repair services at Gadgets Reborn. Specializing in iPhone, Samsung, and major brands. Fast and reliable solutions for all your gadget needs."
        />
        <meta
          name="description"
          content="Welcome to Gadgets Reborn, your trusted partner for all your mobile and laptop repair needs. We're committed to providing exceptional service and high-quality repairs for a wide range of brands, from the latest iPhone 16 Pro Max and Samsung Galaxy S24 to everyday laptops"
        />
      </Helmet>
      <div className="bg-[#FBC903] rounded-b-[40px] pb-[40px] overflow-hidden">
        <Navbar />
        {/* hero section start */}
        <div className="relative flex flex-col items-center">
          <div className="absolute sm:mt-[80px] text-center mt-[90px] sm:z-0 z-[10]">
            <h3 className="sm:text-[62px] text-[36px] font-[700] sm:leading-[72px] text-[#101828]">
              Buy Your Gadget <br />
              Anywhere—We Handle <br /> the Care !
            </h3>
            <p className="text-[#344054] sm:text-[22px] text-[16px] sm:leading-[32px] font-[500] sm:mt-[28px]">
              From quick fixes to complete care, your <br /> devices are in
              trusted hands.
            </p>
          </div>
          <div className="sm:flex sm:justify-center sm:gap-[240px] sm:mt-[190px] mt-[210px] w-full sm:px-4 lg:px-0 sm:mr-0 mr-[100px]">
            <img
              src={Icon2}
              alt=""
              className="filter contrast-125 object-contain sm:max-w-[45%] max-w-[400px] lg:max-w-[680px] mt-[120px] sm:mt-0"
            />
            <img
              src={Icon1}
              alt=""
              className="sm:block hidden filter contrast-125 object-cover max-w-[45%]"
            />
          </div>
          <div className="relative flex flex-col items-center">
            <div className="cursor-pointer relative sm:min-w-[500px] min-w-[350px] z-10 sm:mt-[-90px] mt-[10px] flex items-center bg-white rounded-full overflow-hidden shadow-sm">
              <Select
                className=" px-6 border-0 text-black focus:outline-none flex-grow placeholder:text-[#101828] placeholder:font-[500]"
                showSearch
                placeholder="Explore Solutions Now"
                optionFilterProp="label"
                onChange={onChange}
                onSearch={onSearch}
                options={[
                  {
                    value: "repair",
                    label: "Fix My Device",
                  },
                  {
                    value: "care",
                    label: "Protect My Device",
                  },
                  {
                    value: "certified_devices",
                    label: "Shop Certified Devices",
                  },
                ]}
              />
              <button
                className="bg-[#0C111D] text-white sm:px-6 px-4 text-sm sm:text-[16px] py-4 rounded-r-full hover:bg-gray-800 focus:outline-none"
                onClick={handleGetStarted}
              >
                Get started
              </button>
            </div>
          </div>

          <div className="sm:text-[22px] text-[14px] sm:leading-[32px] font-[500]  text-[#344054] sm:mt-0 mt-4 text-center px-[24px] sm:px-[230px]">
            At Gadgets Reborn, we understand the importance of keeping your
            devices in top condition. Whether you're a gamer, entrepreneur, or
            casual user, our comprehensive servicing and care for your mobile,
            laptop, and gaming console, ensuring they last longer and perform
            better.
          </div>
        </div>
      </div>
      {/* hero section end */}

      {/* second section start  */}

      <div className="sm:mt-[42px] mt-[40px] bg-[#F7F9F8] sm:rounded-[40px] rounded-[24px] mx-[20px] sm:mx-0">
        <div className="grid sm:grid-cols-2 grid-cols-1 items-center justify-center mx-[16px] sm:mx-0 mt-[32px] sm:mt-0">
          <div className="col-span-1 sm:pl-[82px] my-8">
            <h3 className="sm:text-[42px] text-[36px] font-bold text-[#101828] sm:leading-[60px] leading-[44px]">
              Tips for Extending Device Lifespan
            </h3>
            <ul className="list-disc pl-6 mt-[24px] space-y-4">
              <li className="text-[#475467] sm:text-[20px] text-[16px] sm:leading-[30px] leading-[24px] font-[400] font-DMSans">
                <strong>Handle with care:</strong> Avoid drops, bumps, and
                scratches.
              </li>
              <li className="text-[#475467] sm:text-[20px] text-[16px] sm:leading-[30px] leading-[24px] font-[400] font-DMSans">
                <strong>Use protective cases:</strong> Invest in high-quality
                cases and screen protectors.
              </li>
              <li className="text-[#475467] sm:text-[20px] text-[16px] sm:leading-[30px] leading-[24px] font-[400] font-DMSans">
                <strong>Update software regularly:</strong> Stay current with
                security patches and feature updates.
              </li>
              <li className="text-[#475467] sm:text-[20px] text-[16px] sm:leading-[30px] leading-[24px] font-[400] font-DMSans">
                <strong> Monitor battery health:</strong> Avoid overcharging and
                replace batteries as needed.
              </li>
              <li className="text-[#475467] sm:text-[20px] text-[16px] sm:leading-[30px] leading-[24px] font-[400] font-DMSans">
                <strong>Clean devices regularly:</strong> Prevent dust buildup
                and maintain performance.
              </li>
            </ul>
            <p className="text-[#475467] sm:text-[20px] text-[16px] mt-3 ml-4">
              By following these expert tips, you'll be able to enjoy your
              devices for years to come. Remember, regular care and maintenance
              are key to extending device lifespan. If issues arise, trust
              Gadgets Reborn to provide top-notch repair services.
            </p>
            <button
              className="mt-[40px] text-black px-[20px] py-[12px] rounded-md bg-[#E4B703] font-[700]"
              onClick={() => navigate("/care")}
            >
              Explore Plans
            </button>
          </div>
          <div className="col-span-1">
            <img
              className="object-cover rounded-[40px] sm:ml-auto ml-4 mt-[0px] sm:mt-[26px]"
              src={secondBanner}
              alt=""
            />
          </div>
        </div>
      </div>
      {/* second section end  */}

      {/* third section start */}

      <div className="mt-[42px] bg-[#FBC903] sm:rounded-[40px] rounded-[24px] mx-[20px] sm:mx-0">
        <div className="grid sm:grid-cols-5 grid-cols-1 items-center justify-center mx-[16px] sm:mx-0 mt-[32px] sm:mt-0">
          <div className="col-span-2 sm:pl-[82px]">
            <h3 className="sm:text-[46px] text-[30px] font-bold text-[#101828] sm:leading-[60px] leading-[45px]">
              Gadget Heroes: Schedule Pick Up, or Visit a ‘Local Rescue Hero’
              around you!
            </h3>
            <p className="text-[#101828] sm:text-[20px] text-[16px] sm:leading-[30px] leading-[24px] sm:mt-[24px] mt-[16px] font-[500] font-DMSans">
              It’s like ordering your favourite pizza without the stress—
              arrange for a pick-up service! Our automated support and prompt
              solutions ensure that your gadgets return to its functioning
              state, be it a samsung repair or an apple watch fix, our team
              handles the care.
            </p>
            <button
              className="mt-[40px] text-[#344054] px-[20px] py-[12px] rounded-md bg-[#FFFFFF] border-[#D0D5DD] font-[700]"
              onClick={() => navigate("/repair")}
            >
              Repair and Protect my device now
            </button>
          </div>
          <div className="col-span-3">
            <div className="flex items-center justify-center sm:py-[80px] py-[44px]">
              <img className="object-cover" src={Phone} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* third section end */}

      {/* fourth section start */}
      <div className="mb-[100px] my-[120px] text-center mx-[20px] sm:mx-0">
        <h3 className="sm:text-[48px] text-[36px] font-bold text-[#101828] sm:leading-[60px] leading-[44px]">
          What can we fix for you?
        </h3>
        <p className="font-bold text-[24px] mt-4 sm:px-[380px] px-0">
          No matter the issue, our skilled technicians will get your devices
          running like new in no time!
        </p>
        <div className="flex flex-col sm:flex-row justify-center sm:justify-between sm:items-end items-center mt-[52px] sm:px-[136px] space-y-8 sm:space-y-0 sm:space-x-4">
          <div
            className="cursor-pointer flex flex-col items-center"
            onClick={() => navigate("repair/mobile")}
          >
            <img
              className="object-contain sm:w-auto w-[80%]"
              src={Phones}
              alt=""
            />
            <p className="sm:text-[28px] text-[20px] font-[600] text-[#101828]">
              Phones
            </p>
          </div>
          <div
            className="cursor-pointer flex flex-col items-center"
            onClick={() => navigate("repair/laptop")}
          >
            <img
              className="object-contain sm:w-auto w-[80%]"
              src={Laptops}
              alt=""
            />
            <p className="sm:text-[28px] text-[20px] font-[600] text-[#101828]">
              Laptops
            </p>
          </div>
          <div
            className="cursor-pointer flex flex-col items-center"
            onClick={() => navigate("repair/playstation")}
          >
            <img
              className="object-contain sm:w-auto w-[80%]"
              src={ps5}
              alt=""
            />
            <p className="sm:text-[28px] text-[20px] font-[600] text-[#101828]">
              Gaming Console
            </p>
          </div>
          <div
            className="cursor-pointer flex flex-col items-center"
            onClick={() => navigate("repair/tablet")}
          >
            <img
              className="object-contain sm:w-auto w-[80%]"
              src={tablets}
              alt=""
            />
            <p className="sm:text-[28px] text-[20px] font-[600] text-[#101828]">
              Tablets
            </p>
          </div>
        </div>
      </div>
      {/* fourth section end */}

      {/* Testimonials start */}

      <div className="mb-[70px]">
        <TestimonialsRepair />
      </div>

      {/* Testimonials end */}

      {/* Fifth section start */}
      <div className="mb-[100px] flex flex-col-reverse sm:flex-row justify-between mx-[20px] sm:mx-0">
        <div className="sm:w-[50%] sm:mt-[64px] mt-[32px] text-center sm:text-left">
          <h3 className="sm:text-[48px] text-[36px] font-bold text-[#101828] sm:leading-[60px] leading-[44px]">
            Discover How Easy
            <br />
            <a href="https://www.gadgetsreborn.com/" className="underline">
              Gadget{" "}
            </a>{" "}
            Repairs Can Be!
          </h3>
          <p className="text-[#475467] sm:text-[20px] text-[16px] sm:leading-[30px] leading-[24px] mt-[24px] font-[400] font-DMSans">
            When things don’t go as planned, we’re here to quickly fix and
            restore your routine, whether it’s the latest{" "}
            <a
              className="underline"
              href="https://www.gadgetsreborn.com/repair/mobile"
            >
              iphone
            </a>
            , a Samsung smartphone, a laptop or a tab. Download our app for
            seamless booking and repair services.
          </p>
          <button
            className="mt-[40px] text-black px-[20px] py-[12px] rounded-md bg-[#FBC903] font-[700]"
            onClick={() => navigate("/repair")}
          >
            Get the App Now
          </button>
        </div>
        <div className="sm:w-[50%]">
          <img className="object-cover w-full" src={Apps} alt="" />
        </div>
      </div>
      {/* Fifth section end */}

      {/* Footer start */}

      <Footer />

      {/* Footer end */}
    </div>
  );
};

export default LandingPage;
