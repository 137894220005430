import React from "react";
import HeaderRepair from "../../../components/repairGadgets/HeaderRepair";
import FeaturesRepair from "../../../components/repairGadgets/FeaturesRepair";
import TestimonialsRepair from "../../../components/repairGadgets/TestimonialsRepair";
import Footer from "../../../components/Footer";
import { Helmet } from "react-helmet";

const LandingPage = () => {
  return (
    <div className="flex flex-col sm:mx-[50px]">
      <Helmet>
        <meta
          name="title"
          content=" Not just quick fix but value addition and care for your mobile, table & laptop | Gadgets Reborn"
        />

        <meta
          name="description"
          content=" Gadgets Reborn offers cutting-edge repair and care for products that improve your quality of your mobile. Quick turn-around time "
        />

        <link rel="canonical" href="https://www.gadgetsreborn/care" />

        <meta
          name="keywords"
          content="apple iPhone,iphone 14,iphone 15,iphone 14 pro max,iphone 15 pro max,iphone 15 pro max price,iphone 15 for sale,best iphone 15 deals,iphone 14 repair and care,iphone 15 repair and care,iphone 14 repair and service,iphone 15 repair and service,samsung S23 Ultra repair service,iphone screen repair,Repair Iphone,Dubai Warranty extension,Refurbished iphone,refurbished mobiles uae"
        />

        <meta
          property="og:title"
          content=" Not just quick fix but value addition and care for your mobile, table & laptop | Gadgets Reborn "
        />
        <meta property="og:site_name" content="Gadgets Reborn" />
        <meta property="og:url" content="https://www.gadgetsreborn.com/care" />
        <meta
          property="og:description"
          content=" Gadgets Reborn offers cutting-edge repair and care for products that improve your quality of your mobile. Quick turn-around time "
        />
        <meta property="og:type" content="Website" />

        <meta
          name="twitter:title"
          content=" Not just quick fix but value addition and care for your mobile, table & laptop | Gadgets Reborn "
        />
        <meta
          name="twitter:description"
          content=" Gadgets Reborn offers cutting-edge repair and care for products that improve your quality of your mobile. Quick turn-around time "
        />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "gadgetsreborn",
            "url": "https://www.gadgetsreborn.com",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.gadgetsreborn.com/repair{search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }`}
        </script>
      </Helmet>
      <HeaderRepair />
      <FeaturesRepair />
      <TestimonialsRepair />
      {/* <Newsletter/> */}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
