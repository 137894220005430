import React, { useEffect } from "react";
import HeaderRepair from "../components/repairGadgets/HeaderRepair";
import FeaturesRepair from "../components/repairGadgets/FeaturesRepair";
import TestimonialsRepair from "../components/repairGadgets/TestimonialsRepair";
import Newsletter from "../components/repairGadgets/Newsletter";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const RepairGadgetPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col sm:mx-[50px]">
      <Helmet>
        <meta
          name="title"
          content="Repair Your Gadgets with Ease | Gadgets Reborn"
        />
        <meta
          name="description"
          content="At Gadgets Reborn, we provide professional repair services for all your electronic devices. Trust our skilled technicians to restore your gadgets to optimal condition"
        />
        <link rel="canonical" href="https://www.gadgetsreborn.com/repair" />
        <meta
          name="keywords"
          content="apple iPhone,iphone 14,iphone 15,iphone 14 pro max,iphone 15 pro max,iphone 15 pro max price,iphone 15 for sale,best iphone 15 deals,iphone 14 repair and care,iphone 15 repair and care,iphone 14 repair and service,iphone 15 repair and service,samsung S23 Ultra repair service,iphone screen repair,Repair Iphone,Dubai Warranty extension,Refurbished iphone,refurbished mobiles uae"
        />
        <meta
          property="og:title"
          content="Gadgets Reborn | Broken Screens, Faulty Charging slots | We’ll pick up, fix your gadget and bring it back to you"
        />
        <meta property="og:site_name" content="Gadgets Reborn" />
        <meta
          property="og:url"
          content="https://www.gadgetsreborn.com/repair"
        />
        <meta
          property="og:description"
          content=" At Gadgets Reborn, we provide professional repair services for all your electronic devices. Trust our skilled technicians to restore your gadgets to optimal condition "
        />
        <meta property="og:type" content="Website" />

        <meta
          name="twitter:title"
          content="Gadgets Reborn | Broken Screens, Faulty Charging slots | We’ll pick up, fix your gadget and bring it back to you "
        />
        <meta
          name="twitter:description"
          content=" At Gadgets Reborn, we provide professional repair services for all your electronic devices. Trust our skilled technicians to restore your gadgets to optimal condition."
        />
        <meta />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "Gadgets Reborn",
            "url": "https://www.gadgetsreborn.com",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.gadgetsreborn.com/repair{search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }`}
        </script>
      </Helmet>
      <HeaderRepair />
      <FeaturesRepair />
      <TestimonialsRepair />
      {/* <Newsletter /> */}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default RepairGadgetPage;
